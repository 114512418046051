import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import FigmaEmbed from 'components/FigmaEmbed';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "about",
      "style": {
        "position": "relative"
      }
    }}>{`About`}</h2>
    <p>{`A dialog displays content in front of an app or website without losing context
of the page beneath it. Use a dialog when you require a user's response or want
to display critical information or a warning where a response is needed. They're
intended to support short secondary tasks initiated by the user.`}</p>
    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}</h2>
    <p>{`Use a dialog when a user needs to confirm or take an important action like
logging in or signing up. Always use them in a thoughtful and intentional way,
because they interrupt a user's experience and can be perceived as annoying.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=722%3A14" mdxType="FigmaEmbed" />
    <h3 {...{
      "id": "how-to-use",
      "style": {
        "position": "relative"
      }
    }}>{`How to use`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`User-initiated`}</strong>{`: Do not trigger dialogs without a user taking an action
e.g. page loads.`}</li>
      <li parentName="ul"><strong parentName="li">{`Dismissable`}</strong>{`: Every dialog can be dismissed in different ways. Either by
clicking the `}<inlineCode parentName="li">{`X`}</inlineCode>{` button in the header, pressing the `}<inlineCode parentName="li">{`escape`}</inlineCode>{` key or clicking
the backdrop. If the user's abillity to dismiss a dialog is disabled, the user
must choose an action to proceed.`}</li>
      <li parentName="ul"><strong parentName="li">{`A sub-task`}</strong>{`: The user must take action, either in completing the sub-task
or dismissing the window, to return to the main interface.`}</li>
    </ul>
    <h3 {...{
      "id": "how-not-to-use",
      "style": {
        "position": "relative"
      }
    }}>{`How not to use`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Displayed in groups`}</strong>{`: Do not open a dialog from inside another dialog.
There should only be one dialog displayed at a time.`}</li>
      <li parentName="ul"><strong parentName="li">{`Multistep`}</strong>{`: Avoid dialogs with multiple steps that require navigation
within the dialog.`}</li>
    </ul>
    <h2 {...{
      "id": "default-dialog",
      "style": {
        "position": "relative"
      }
    }}>{`Default Dialog`}</h2>
    <p>{`The default dialog on tablet and desktop is always accompanied by a backdrop.
The header, containing the close button, is optional and can be disabled if
desired.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/
Chameleon-Best-Practices?node-id=738%3A133" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "mobile",
      "style": {
        "position": "relative"
      }
    }}>{`Mobile`}</h2>
    <p>{`On our web products, the dialog screen becomes a full screen experience with no
backdrop. For our native app products, design seperate interfaces follwing the
`}<a parentName="p" {...{
        "href": "https://developer.apple.com/design/human-interface-guidelines/ios/app-architecture/modality/"
      }}>{`modal view`}</a>{`
guidelines for iOS or the `}<a parentName="p" {...{
        "href": "https://material.io/components/dialogs"
      }}>{`dialog`}</a>{`
guidelines for Android.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=738%3A566" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "behaviour",
      "style": {
        "position": "relative"
      }
    }}>{`Behaviour`}</h2>
    <p>{`A dialog always appears in front of the page, covering the page beneath it with
a backdrop, so that the focus is on the dialog.`}</p>
    <h3 {...{
      "id": "closing-a-dialog",
      "style": {
        "position": "relative"
      }
    }}>{`Closing a dialog`}</h3>
    <p>{`A dialog can be dismissed in different ways, this way the component meets
accessibility guidelines for both mouse and keyboard interactions.`}</p>
    <ul>
      <li parentName="ul">{`Clicking the close button in the header`}</li>
      <li parentName="ul">{`Clicking anywhere on the backdrop`}</li>
      <li parentName="ul">{`Pressing `}<inlineCode parentName="li">{`Esc`}</inlineCode>{` on your keyboard`}</li>
    </ul>
    <h3 {...{
      "id": "sizing",
      "style": {
        "position": "relative"
      }
    }}>{`Sizing`}</h3>
    <p>{`Dialogs have a fixed width, but can differ in height.The height is determined by
how much content appears in the dialog. Once it reaches a certain size, the body
of the dialog will scroll while the header remains fixed.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=738%3A0" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "related-components",
      "style": {
        "position": "relative"
      }
    }}>{`Related components`}</h2>
    <p>{`If you're looking to alert users about important information, use a
`}<a parentName="p" {...{
        "href": "/components/banner/code"
      }}>{`Banner`}</a>{` component.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      